// gatsby-browser.js
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapPageElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdWErseAAAAAOzEHz1w8bt4OD5Rrn4rscUc3eRV"
    >
      {element}
    </GoogleReCaptchaProvider>
  );
};
